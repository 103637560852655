import React from 'react';

export const FolderIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  color = '#191B21',
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.45 4.88017H16.52C20.21 4.88017 22.01 6.85017 22 10.8902V15.7602C22 19.6202 19.62 22.0002 15.75 22.0002H8.24C4.39 22.0002 2 19.6202 2 15.7502V8.24017C2 4.10017 3.84 2.00017 7.47 2.00017H9.05C9.981 1.99017 10.85 2.42017 11.42 3.15017L12.3 4.32017C12.58 4.67017 13 4.88017 13.45 4.88017ZM7.63 13.7129C7.21579 13.7129 6.88 14.0487 6.88 14.4629C6.88 14.8771 7.21579 15.2129 7.63 15.2129H16.365C16.7792 15.2129 17.115 14.8771 17.115 14.4629C17.115 14.0487 16.7792 13.7129 16.365 13.7129H7.63Z"
        fill={color}
      />
    </svg>
  );
};
