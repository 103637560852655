import React from 'react';

export const TickIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  color = '#191B21',
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.67 1.99988H16.34C19.73 1.99988 22 4.37988 22 7.91988V16.0909C22 19.6199 19.73 21.9999 16.34 21.9999H7.67C4.28 21.9999 2 19.6199 2 16.0909V7.91988C2 4.37988 4.28 1.99988 7.67 1.99988ZM16.0903 10.1572C16.3832 9.86427 16.3832 9.38939 16.0903 9.0965C15.7974 8.80361 15.3225 8.80361 15.0296 9.0965L10.8138 13.3123L8.97016 11.4694C8.6772 11.1766 8.20233 11.1767 7.9095 11.4696C7.61667 11.7626 7.61677 12.2374 7.90972 12.5303L10.2837 14.9033C10.5766 15.1961 11.0514 15.196 11.3443 14.9032L16.0903 10.1572Z"
        fill={color}
      />
    </svg>
  );
};
