import React from 'react';

export const UsersChatIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  color = '#FFF',
  ...props
}) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.913 0.658232C8.12005 0.112621 12.3799 0.112621 16.587 0.658232C18.509 0.908232 19.878 2.51923 19.992 4.38523C19.6577 4.27386 19.3112 4.20301 18.96 4.17423C16.1582 3.94164 13.3418 3.94164 10.54 4.17423C8.182 4.37023 6.5 6.36423 6.5 8.60823V12.8942C6.49906 13.718 6.72577 14.526 7.15512 15.2291C7.58446 15.9321 8.19973 16.5028 8.933 16.8782L6.28 19.5302C6.17511 19.635 6.04153 19.7063 5.89614 19.7352C5.75074 19.7641 5.60004 19.7492 5.46308 19.6925C5.32611 19.6358 5.20903 19.5398 5.12661 19.4166C5.04419 19.2933 5.00013 19.1485 5 19.0002V14.9702C4.6372 14.9317 4.27484 14.889 3.913 14.8422C1.905 14.5802 0.5 12.8332 0.5 10.8622V4.63823C0.5 2.66823 1.905 0.920232 3.913 0.659232V0.658232Z"
        fill={color}
      />
      <path
        d="M14.75 5.5C13.374 5.5 12.011 5.557 10.664 5.669C9.124 5.797 8 7.103 8 8.609V12.894C8 14.401 9.128 15.708 10.67 15.834C11.913 15.936 13.17 15.991 14.438 15.999L17.22 18.78C17.3249 18.8848 17.4585 18.9561 17.6039 18.985C17.7493 19.0139 17.9 18.999 18.0369 18.9423C18.1739 18.8856 18.291 18.7896 18.3734 18.6663C18.4558 18.5431 18.4999 18.3982 18.5 18.25V15.86L18.83 15.834C20.372 15.709 21.5 14.401 21.5 12.894V8.608C21.5 7.103 20.375 5.797 18.836 5.668C17.4769 5.55562 16.1137 5.49957 14.75 5.5Z"
        fill={color}
      />
    </svg>
  );
};
